<div class="section grid burger-header">
  <div class="section-content grid align-items-center full-width">
    <ng-container *ngIf="storage.cityName$ | async as cityName">
      <div class="icon icon-16 icon-global mr-4 white"></div>
      <span
        class="link typography typography-caption typography-medium grey-800 white"
        (click)="openCityModal()"
        ymGoal
        [nameGoal]="YmGoalHeader.SelectCityClick"
      >
        <span data-testid="current-city" class="typography mr-4"
          >{{ cityName }}
        </span>
        <span class="icon icon-chevron-down chevron-down"></span>
      </span>
    </ng-container>
  </div>

  <div
    data-testid="close-button"
    class="close-button mr-8 white"
    (click)="emitCloseBurger()"
  ></div>
</div>

<div class="grid grid-column justify-content-between burger-container">
  <div class="section grid burger-container">
    <div class="grid grid-column justify-content-between full-width">
      <div class="grid grid-column mb-128">
        <a
          class="typography typography-headline burger-item"
          *ngFor="let item of itemsBurger"
          [routerLink]="item.link"
          (click)="emitCloseBurger()"
          [attr.data-testid]="item.testId"
        >
          {{ item.title }}
        </a>

        <nz-collapse>
          <nz-collapse-panel [nzHeader]="itemsCollapse[0]" nzShowArrow="false" data-testid="adaptive-header-nav-services">
            <div
              class="grid grid-column grid-gutter-40 typography typography-body grey-700 pl-20"
            >
              <div
                data-testid="order-help"
                (click)="writeToHelixModalService.openIfns()"
                class="grid-item"
              >
                Заказ справки для налоговой
              </div>

              <div
                data-testid="write-to-helix"
                (click)="writeToHelixModalService.openDefault()"
                class="grid-item"
              >
                Написать в Хеликс
              </div>

              <div
                data-testid="send-message-error"
                (click)="reportErrorModalService.open()"
                class="grid-item"
              >
                Сообщить об ошибке на сайте
              </div>
            </div>
          </nz-collapse-panel>

          <nz-collapse-panel [nzHeader]="itemsCollapse[1]" nzShowArrow="false" data-testid='adaptive-header-nav-info'>
            <div
              class="grid grid-column grid-gutter-40 typography typography-body grey-700 pl-20"
            >
              <a
                class="grid-item"
                *ngFor="let item of itemsAdditionalInfo"
                [attr.data-testid]="item.testId"
                [routerLink]="item.link"
                (click)="emitCloseBurger()"
              >
                {{ item.title }}
              </a>
            </div>
          </nz-collapse-panel>
        </nz-collapse>

        <app-visually-impaired-button
          class="mt-28"
          *ngIf="!(visuallyImpaired.stateVI$ | async)"
          (click)="switchOnVI()"
        ></app-visually-impaired-button>
      </div>
    </div>
  </div>

  <div>
    <div class="section section-border">
      <div class="section-content">
        <div class="grid grid-column pt-40 pb-48">
          <div
            class="grey-500 typography typography-caption typography-subheadline typography-medium mb-20"
            data-testid="adaptive-header-nav-about-company"
          >
            О компании
          </div>
          <div class="grid grid-column grid-gutter-40 typography">
            <a
              class="grid-item"
              *ngFor="let item of itemsAboutCompany"
              [routerLink]="item.link"
              (click)="emitCloseBurger()"
              [attr.data-testid]="item.testId"
            >
              {{ item.title }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div
      class="typography typography-headline typography-medium grey-700 mt-40 mb-44 grid grid-xs-column justify-content-center"
    >
      <div
        class="centered phone-margin"
        *ngIf="storage.companyPhone$ | async as companyPhone"
      >
        <div class="icon icon-phone icon-20 mr-4"></div>
        <a href="tel:{{ companyPhone }}">{{ companyPhone }}</a>
      </div>

      <div
        class="centered"
        *ngIf="storage.federalPhone$ | async as federalPhone"
      >
        <div class="icon icon-phone icon-20 mr-4"></div>
        <a href="tel:{{ federalPhone }}">{{ federalPhone }}</a>
      </div>
    </div>
  </div>
</div>
