export enum Agreements {
  PrivateAgreement = '/Upload/Documents/Пользовательское_соглашение.pdf',
  MedicalServiceAgreement = '/Upload/documents/Оферта_платные_мед_услуги_НПФ.pdf',
  PaidServiceAgreement = '/Upload/documents/Оферта_услуги_НПФ.pdf',
  PolicyRegardingProcessingPersonalData = '/Upload/Documents/data-processing-policy.pdf',
  LoyaltyProgramRules = '/Upload/Documents/правила программы лояльности.pdf',
  PersonalDataProcessingAgreement = '/site/page/63',
  PaidServiceAgreement_nv = '/Upload/documents/Оферта_услуги_Норд-Вест.pdf',
  MedicalServiceAgreement_nv = '/Upload/documents/оферта_платные_мед_услуги_хеликс норд-вест.pdf',
  PaidServiceAgreement_novosib = '/Upload/documents/оферта_услуги_хеликс_новосибирск.pdf',
  MedicalServiceAgreement_novosib = '/Upload/documents/оферта_платные_мед_услуги_хеликс-новосибирск.pdf',
  OosPolitics = '/Upload/documents/oos-politics.docx',
}
