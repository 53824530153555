<a
  class="grid-item typography typography-body typography-xs gray-600"
  *ngFor="let item of subNavigation; trackBy: trackBy"
  [routerLink]="item.link"
>
  {{ item.title }}</a
>

<a
  class="grid-item typography typography-body typography-xs gray-600"
  [href]="Agreements.PolicyRegardingProcessingPersonalData"
>
  Обработка персональных данных
</a>

<a
  class="grid-item typography typography-body typography-xs gray-600"
  [href]="Agreements.OosPolitics"
>
  Политика в области качества, ООС, ПЗБТ
</a>
